<template>
  <div v-loading="loading">
    <section>
      <article class="container w-100 text-left">
        <h1>Website Dashboard</h1>
        <div class="cards-grid row w-100">
          <div class="col-md-3 col-sm-6 col-12 pt-2 pl-3 pr-0">
            <div class="home-cards">
              <div class="card-details">
                <h2>Website Admins</h2>
                <p class="total">{{ webAdminsCount }}</p>
                <p class="text">Website Admins</p>
              </div>
              <div class="card-icon icon1"><i class="el-icon-user p-2"></i></div>
            </div>
          </div>

          <div class="col-md-3 col-sm-6 col-12 pt-2 pl-3 pr-0">
            <div class="home-cards">
              <div class="card-details">
                <h2>Daily Visits</h2>
                <p class="total">{{ todaysCount }}</p>
                <p class="text">Website Visitors today</p>
              </div>
              <div class="card-icon icon2"><i class="el-icon-time"></i></div>
            </div>
          </div>

          <div class="col-md-3 col-sm-6 col-12 pt-2 pl-3 pr-0">
            <div class="home-cards">
              <div class="card-details">
                <h2>Weekly Visits</h2>
                <p class="total">{{ totalWeekCount }}</p>
                <p class="text">Website Visitors this Week</p>
              </div>
              <div class="card-icon icon3"><i class="el-icon-time"></i></div>
            </div>
          </div>

          <div class="col-md-3 col-sm-6 col-12 pt-2 pl-3 pr-0">
            <div class="home-cards">
              <div class="card-details">
                <h2>Last 60 days Visits</h2>
                <p class="total">{{ last60DaysVisitCount }}</p>
                <p class="text">Website Visitors in the past 60 Days</p>
              </div>
              <div class="card-icon icon4"><i class="el-icon-time"></i></div>
            </div>
          </div>
          <GraphStatisticsComponent
            class="col-12 pl-3 pr-0"
            :last60DaysVisits="last60DaysVisits"
            :chartKey="chartKey"
      />
        </div>
      </article>
    </section>
  </div>
</template>

<script>
import GraphStatisticsComponent from "../components/graph-statistics.vue";
export default {
  components: {
    GraphStatisticsComponent,
  },
  data() {
    return {
      chartKey: 0,
      loading: false,
      webAdminsCount: 0,
      todaysCount: 0,
      totalWeekCount: 0,
      last60DaysVisitCount: 0,
      last60DaysVisits: [],
    };
  },
  mounted() {
    this.fetchWebsiteAdminDashboardHome();
  },
  methods: {
    async fetchWebsiteAdminDashboardHome() {
      try {
        this.loading = true;
        let request = await this.$http.get('website/dashboard-home');
        if (
          request.data.success &&
          request.data.message == "DASHBOARD DATA FETCHED SUCCESSFULLY"
        ) {
          this.webAdminsCount = request.data.data.web_admins_count;
          this.last60DaysVisitCount = request.data.data.last_60_days_visit_count;
          this.todaysCount = request.data.data.today_count;
          this.totalWeekCount = request.data.data.total_week_count;
          this.last60DaysVisits = request.data.data.visits_in_last_60_days;
          this.chartKey++;
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.showFailedMessage(
          "Unable to load admin data",
          "An unexpected error occurred, please try again"
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 1.8em;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 30px;
}
/* .cards-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
} */
.cards-grid .home-cards {
  height: 120px;
  border-radius: 10px;
  position: relative;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.cards-grid .home-cards .card-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 45px;
  height: 45px;
  border-radius: 15px;
  font-size: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cards-grid .home-cards .icon1 {
  background-color: #e8e6ff;
  color: #807bd8;
}
.cards-grid .home-cards .icon2 {
  background-color: #fef3cb;
  color: #e7c15a;
}
.cards-grid .home-cards .icon3 {
  background-color: #d8fbeb;
  color: #92cdb2;
}
.cards-grid .home-cards .icon4 {
  background-color: #fddbd3;
  color: #fac3af;
}
.span-text {
  color: rgba(0, 128, 0, 1);
}
.card-details {
  padding: 15px;
}
.card-details h2 {
  font-size: 0.8em;
  font-weight: 600;
  color: grey;
  padding: 0px;
  margin: 0px;
}
.card-details .total {
  font-size: 1.5em;
  font-weight: 600;
}
.card-details .text {
  font-size: 0.8em;
  padding: 0px;
  margin: 0px;
}
/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (max-width: 480px) {
}
</style>