<template>
  <section>
    <article class="text-left">
      <div class="statistics">
        <h2>Website Visits in last 60 Days</h2>
        <div id="chart">
          <div>
            <VueApexCharts
              width="100%"
              :key="chartKey"
              style="pointer-events: none !important"
              type="area"
              height="420px"
              :options="options"
              :series="series"
            />
          </div>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    VueApexCharts,
  },

  props: {
    last60DaysVisits: {
      type: Array,
      required: true,
    },
    chartKey: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      options: {
        dataLabels: { enabled: false },
        tooltip: { enabled: false },
        zoom: {
          enabled: false,
        },
        chart: {
          toolbar: { show: false },
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          labels: { show: false },
        },
        yaxis: {
          min: 0,
          // max: 100,
          // tickAmount: 5,
        },
      },
      series: [
        {
          name: "series-1",
          // data: [30, 20, 22, 11, 0, 4, 5, 20, 21, 76, 88, 54, 0, 0, 3, 5, 43],
          data: [],
        },
      ],
    };
  },

  watch: {
    last60DaysVisits: {
      handler: function (val) {
        this.series[0].data = val;
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.statistics {
  height: 520px;
  border-radius: 10px;
  margin-top: 20px;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px 15px;
  margin-left: 0;
}

.statistics h2 {
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 30px;
}
</style>
